// build a new URL with parameters p0-p6, fbclid and fbpaid
export function buildURL(p0, p1, p2, p3, p4, p5, p6, apkUrl, fbclid, fbpid) {
    console.log('33333333')
    if (!p0) {
      return null;
    }
    let tracker_token = p0;
    if (p1 || p2) {
      var campaign = p1 + "(" + p2 + ")";
    } else {
        var campaign = "";
    }
    if (p3 || p4) {
        var adgroup = p3 + "(" + p4 + ")";
    } else {
        var adgroup = "";
    }
    if (p5 || p6) {
        var creative = p5 + "(" + p6 + ")";
    } else {
        var creative = "";
    }
    if (apkUrl) {
        var redirect = apkUrl;
    }
    if (!fbclid) {
        var fbclid = "";
    }
    if (!fbpid) {
        var  fbPid = "";
    }
    // build a tracker URL
    let params = { campaign, adgroup, creative, redirect, fbclid, fbpid };
    let newURL =
      "https://app.adjust.com/" +
      tracker_token +
      "?" +
      Object.keys(params)
        .map((key) => key + "=" + encodeURIComponent(params[key]))
        .join("&");
    return newURL;
  }
  
  //get fbpid from Cookie written by Facebook Pixel
  export function getFbPid() {
    let fbPid = document.cookie.match(/(^|;) ?_fbp=([^;]*)(;|$)/);
    if (fbPid) {
      return fbPid[2];
    } else {
      return "";
    }
  }