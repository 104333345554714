<template>
  <div class="home" @click="gotoapk">
    <img alt="Vue logo" src="../assets/yuenan.gif">
  </div>
</template>

<script>
import { buildURL, getFbPid } from '../Adjustscript/Adjustscript'
export default {
  name: 'HomeView',
  data() {
    return {
      url: '',
      iosUrl: 'https://apps.apple.com/app/id6458542341'
    }
  },
  created() {
    const urlSearch=window.location.search
    let urlArr=[]
    urlArr=urlSearch.split('?')
    for(let i in urlArr){
      urlArr[i]=urlArr[i].split('%3F')
    }
    let pppp=''
    for(let i in urlArr){
      for(let j in urlArr[i]){
        if(urlArr[i][j].includes('p0')){
          pppp=urlArr[i][j]
        }
      }
    }
    let ppppArr=pppp.split('&')
   

    let p0 = ppppArr[0].replace('p0=','').replace('p0%3D','')
    console.log('333', p0)
    let p1 = this.$route.query.p1
    let p2 = this.$route.query.p2
    let p3 = this.$route.query.p3
    let p4 = this.$route.query.p4
    let p5 = this.$route.query.p5
    let p6 = this.$route.query.p6
    let fbPid = getFbPid();
    let fbClickId = this.$route.query.fbclid
    let apkUrl = "https://k8n.link/img/k8n.apk";
    let url = buildURL(p0, p1, p2, p3, p4, p5, p6, apkUrl, fbClickId, fbPid);
    if(url){
      this.url = url
    }else{
      this.url = apkUrl
    }
    
    console.log(url)
    // add links
    window.addEventListener("DOMContentLoaded", function () {
      if (url) {
        var elements = document.querySelectorAll(".AdjustTracker");
        for (var i = 0; i < elements.length; i++) {
          elements[i].setAttribute("href", url);
        }
      } else {
        var elements = document.querySelectorAll(".AdjustTracker");
        for (var i = 0; i < elements.length; i++) {
          elements[i].setAttribute("href", apkUrl);
        }
      }
    });
  },
  methods: {
    gotoapk() {
      console.log('goto')
      window.location = this.url
      if(this.isAppleMobileDevice()){
        console.log('ios')
        window.location = this.iosUrl
      }

    },
    isAppleMobileDevice() {
      // User-Agent detection from: http://www.quirksmode.org/js/detect.html
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPhone|iPad|iPod/i.test(userAgent);
    }
  }
}
</script>
<style lang="less" scoped>
  .home{
    img{
      width:100%;
    }
  }
</style>
